import React from 'react';

import Layout from '../components/layout';
import Head from '../components/head';
import PortfolioItem from '../components/portfolioItem';
import ContactMe from '../components/contactMe';

import stayOnTrackPreview from '../assets/portfolio/stay-on-track-preview.gif';
import inStockPreview from '../assets/portfolio/instock-preview.png';
import squirrelsOfCentralParkPreview from '../assets/portfolio/squirrels-of-central-park-preview.gif';

const Portfolio = () => {

  return (
    <Layout>
      <Head title="Portfolio" />
      <h1 className="mb-6 text-3xl font-bold">Portfolio</h1>
      <div className="flex flex-col items-center">
        <div className="flex flex-col lg:flex-row items-center lg:items-start justify-center mb-8">
          <PortfolioItem
            appLink="https://stay-on-track-app.herokuapp.com/"
            gitLink="https://github.com/jmkaneshiro/stayontrack"
            image={stayOnTrackPreview}
            alt="Walkthrough of features on StayOnTrack application"
            title="StayOnTrack"
            description="A clone of Pivotal Tracker -- an agile project management tool. Users can create projects with stories of three kinds: features, bugs, and chores. A user can be a member of multiple projects, including those owned by other users."
            technologies="Ruby on Rails, React, Redux"
          />
          <PortfolioItem
            appLink="http://nstockapp.herokuapp.com/"
            gitLink="https://github.com/Solomon-T/instock"
            image={inStockPreview}
            alt="Map view of InStock application"
            title="InStock"
            description="Built during the 2020 Covid-19 epidemic with three classmates at App Academy, this app allows users to report and geolocate stores in their neighborhoods that have toilet paper in stock. If a report is accurate, logged in neighbors can verify it with a thumbs up."
            technologies="MongoDB, Express, React, Node.js, Google Maps API"
          />
          <PortfolioItem 
            appLink="https://jmkaneshiro.github.io/squirrels-of-central-park/"
            gitLink="https://github.com/jmkaneshiro/squirrels-of-central-park"
            image={squirrelsOfCentralParkPreview}
            alt="Walkthrough of features on Squirrels of Central Park application"
            title="Squirrels of Central Park"
            description="Presents data and stories collected from the 2018 Squirrel Census in a whimsical and informative way. Users can view records squirrel sightings on a Google Map and learn about the experiences of the 323 squirrel sighters involved in the project."
            technologies="JavaScript, HTML5, CSS3, Leaflet.js, GeoJSON"
          />
        </div>
        <ContactMe />
      </div>
    </Layout>
  )
}

export default Portfolio;