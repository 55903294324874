import React from 'react';

/**
 * Function to create portfolio items on portfolio page
 * @function card
 * @param {object} props React props
 * @param {string} props.appLink link to project
 * @param {string} props.gitLink link to the git source
 * @param {string} props.image path to image source
 * @param {string} props.alt alt text for image
 * @param {string} props.title title of the project
 * @param {string} props.description description of the project
 *  * @param {string} props.technologies technologies used in project
 * @returns {JSX.Element} Rendered component
 */

const PortfolioItem = ({ appLink, gitLink, image, alt, title, description, technologies }) => {
  return (
    <article className="md:w-4/5 h-full p-4 flex flex-col items-center border-gray-500 border-t">
        <h3 className="text-xl text-gray-600 font-semibold pb-2">{title}</h3>
        <a href={appLink} target="_blank" rel="noreferrer">
          <img className="shadow hover:shadow-2xl" src={image} alt={alt} />
        </a>
        <div className="w-full pt-4 flex justify-between text-xs md:text-base">
          <a className="w-1/2 text-center mr-2 py-2 rounded bg-teal-500 hover:bg-teal-800 text-teal-100" href={gitLink} target="_blank" rel="noreferrer">Git Source</a>
          <a className="w-1/2 text-center ml-2 py-2 rounded bg-teal-500 hover:bg-teal-800 text-teal-100" href={appLink} target="_blank" rel="noreferrer">Live Site</a>
        </div>
      <div className="text-sm md:text-base">
          <p className="py-4">{description}</p>
          <p><strong>Technologies used: </strong>{technologies}</p>
        </div>
    </article>
  )
}

export default PortfolioItem;